<!--
File: TreatmentMatrixEditForm.vue
Description: form for adding/editing a single row of the Treatment Matrix.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <TreatmentsDropdown class='road-class-select-dropdown' :label="$t('road_network.treatment_id')"
            v-model="treatment_id" data-vv-name="treatment_id" :initial_value="treatment_id" :is_required="true" />

          <md-field :class="[{ 'md-valid': !errors.has('criteria_set') }, { 'md-error': errors.has('criteria_set') }]">
            <label>{{ $t('road_network.criteria_set') }}</label>
            <md-input v-model="criteria_set" type="number" data-vv-name="criteria_set" required md-numeric
              v-validate="modelValidations.criteria_set"></md-input>
          </md-field>

          <ThresholdVariableDropdown class='road-class-select-dropdown' :label="$t('road_network.treatment_id')"
            v-model="threshold_variable" data-vv-name="threshold_variable" :initial_value="threshold_variable"
            :is_required="true" />

          <md-field :class="[{ 'md-valid': !errors.has('low_threshold') }, { 'md-error': errors.has('low_threshold') }]">
            <label>{{ $t('road_network.low_threshold') }}</label>
            <md-input v-model="low_threshold" type="number" data-vv-name="low_threshold" required md-numeric
              v-validate="modelValidations.low_threshold"></md-input>
          </md-field>

          <md-field
            :class="[{ 'md-valid': !errors.has('high_threshold') }, { 'md-error': errors.has('high_threshold') }]">
            <label>{{ $t('road_network.high_threshold') }}</label>
            <md-input v-model="high_threshold" type="number" data-vv-name="high_threshold" required md-numeric
              v-validate="modelValidations.high_threshold"></md-input>
          </md-field>

          <md-field
            :class="[{ 'md-valid': !errors.has('number_units') }, { 'md-error': errors.has('number_units') }]">
            <label>{{ $t('road_network.number_units') }}</label>
            <md-input v-model="number_units" type="number" data-vv-name="number_units" required md-numeric
              v-validate="modelValidations.number_units"></md-input>
          </md-field>

          <UnitsDropdown :label="$t('road_network.unit_id')" v-model="unit" data-vv-name="unit" :is_required="true"
            v-validate="modelValidations.unit" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field
            :class="[{ 'md-valid': !errors.has('unit_cost_kgs') }, { 'md-error': errors.has('unit_cost_kgs') }]">
            <label>{{ $t('stdCols.unit_cost_kgs') }}</label>
            <md-input v-model="unit_cost_kgs" type="number" data-vv-name="unit_cost_kgs" required
              v-validate="modelValidations.unit_cost_kgs"></md-input>
          </md-field>

          <md-field
            :class="[{ 'md-valid': !errors.has('updated_year') }, { 'md-error': errors.has('updated_year') }]">
            <label>{{ $t('stdCols.updated_year') }}</label>
            <md-input v-model="updated_year" type="number" data-vv-name="updated_year" required
              v-validate="modelValidations.updated_year"></md-input>
          </md-field>
        </div>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import UnitsDropdown from '@/pages/Components/UnitsDropdown.vue'
  import TreatmentsDropdown from './TreatmentsDropdown.vue'
  import ThresholdVariableDropdown from './ThresholdVariableDropdown.vue'
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'treatment-matrix-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        row_id: null,
        treatment_id: null,
        treatment_key: '',
        criteria_set: null,
        threshold_variable: null,
        low_threshold: null,
        high_threshold: null,
        number_units: null,
        unit: null,
        unit_cost_kgs: null,
        updated_year: null,

        modelValidations: {
          treatment_id: { required: true, numeric: true },
          criteria_set: { required: true, numeric: true },
          threshold_variable: { required: true, min: 3 },
          low_threshold: { required: true, numeric: true },
          high_threshold: { required: true, numeric: true },
          number_units: { required: true, numeric: true },
          unit: { required: true, numeric: true },
          unit_cost_kgs: { required: true, numeric: true },
          road_description_ru: { required: true, min: 3 },
          updated_year: { required: true, numeric: true }
        }
      }
    },

    props: {
      oper: String
    },

    components: {
      TreatmentsDropdown,
      ThresholdVariableDropdown,
      UnitsDropdown
    },

    mounted() {
      const { treatment_id = null } = this.$route.params
      this.row_id = treatment_id
      if (this.oper === 'upd' && treatment_id) {
        this.$store.dispatch('LOAD_TREATMENT_MATRIX_ITEM', treatment_id).then((res) => {
          const theRow = Array.isArray(res) ? res[0] : res
          this.threshold_variable = theRow.threshold_variable
          this.treatment_id = theRow.treatment_id
          this.criteria_set = theRow.criteria_set
          this.low_threshold = theRow.low_threshold
          this.high_threshold = theRow.high_threshold
          this.number_units = theRow.number_units
          this.units = theRow.units
          this.unit_cost_kgs = theRow.unit_cost_kgs
          this.updated_year = theRow.updated_year
        })
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return
        const theRow = {
          treatment_id: this.treatment_id,
          criteria_set: Number(this.criteria_set),
          threshold_variable: this.threshold_variable,
          low_threshold: Number(this.low_threshold),
          high_threshold: Number(this.high_threshold),
          number_units: Number(this.number_units),
          unit_cost_kgs: Number(this.unit_cost_kgs),
          updated_year: Number(this.updated_year)
        }

        const payload = this.oper === 'add' ? theRow : { id: this.row_id, theRow }
        let errDesc = ''
        try {
          await this.$store.dispatch(`${this.oper.toUpperCase()}_TREATMENT_MATRIX_ITEM`, payload)
        } catch (err) {
          errDesc = err
        }
        this.savedMessage(errDesc, this.$t('route.treatment_matrix'), '')
        this.onClose()
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    },

    watch: {
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
  